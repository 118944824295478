<template>
  <div v-if="notification.show">
    <v-snackbar
      v-model="notification.is_success"
      color="green"
    >
      {{ notification.message }}
    </v-snackbar>
    <v-snackbar
      v-model="notification.is_error"
      color="red"
    >
      {{ notification.message }}
    </v-snackbar>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Notification',
    computed: mapState(['notification']),
  }
</script>
